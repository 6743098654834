/* eslint-disable prettier/prettier */
const singleProjectPage = document.querySelector('body.single-project');
const projectImageGallery = document.querySelector('.project-image-gallery');
const seeMore = document.querySelector('.see-more');
const header = document.querySelector('header');
const footer = document.querySelector('footer');

if (singleProjectPage && header && footer) {
    header.style.display = 'none';
    footer.style.display = 'none';
}

if (seeMore && projectImageGallery) {
    seeMore.addEventListener('click', () => {
        projectImageGallery.classList.add('active');
    });
}
