/* eslint-disable import/no-extraneous-dependencies */
import Splide from '@splidejs/splide';

// TESTIMONIES CARROUSEL
if (document.querySelectorAll('.splide.testimonies').length > 0) {
    const testimoniesSplides = document.querySelectorAll('.splide.testimonies');

    testimoniesSplides.forEach((splide) => {
        const splideInstance = new Splide(splide, {
            type: 'slide',
            fixedWidth: 400,
            arrows: false,
            pagination: false,
            drag: 'free',
            snap: false,
            speed: 1000,
            breakpoints: {
                1200: {
                    fixedWidth: 350,
                },
                480: {
                    fixedWidth: 280,
                },
            },
        });

        splideInstance.mount();
    });
}

if (document.querySelectorAll('.splide.projects').length > 0) {
    const projectsSplides = document.querySelectorAll('.splide.projects');

    projectsSplides.forEach((splide) => {
        const splideInstance = new Splide(splide, {
            type: 'slide',
            fixedWidth: 400,
            arrows: false,
            pagination: false,
            drag: 'free',
            snap: false,
            speed: 1000,
            breakpoints: {
                1200: {
                    fixedWidth: 350,
                },
                480: {
                    fixedWidth: 280,
                },
            },
        });

        splideInstance.mount();
    });
}
