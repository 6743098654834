/* eslint-disable prettier/prettier */
const gravityForm = document.querySelector('.gravity-theme form');
const gravityFormLive = document.querySelector('.gform-theme form');

const handleFormFields = (form) => {
    if (form) {
        const fields = form.querySelectorAll('.gfield input, .gfield textarea');

        fields.forEach((field) => {
            const gfield = field.closest('.gfield');

            function updateFocusWithin() {
                if (field.value.trim() !== '') {
                    gfield.classList.add('focus-within');
                } else {
                    gfield.classList.remove('focus-within');
                }
            }

            field.addEventListener('input', updateFocusWithin);
            field.addEventListener('change', updateFocusWithin);

            updateFocusWithin();
        });
    }
};

handleFormFields(gravityForm);
handleFormFields(gravityFormLive);

document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        const scrollToElement = (selector) => {
            const element = document.querySelector(selector);
            
            if (element && window.locoScroll) {
                window.locoScroll.scrollTo(element, {
                    offset: -100,
                });
            }
        };

        scrollToElement('.gform_confirmation_wrapper');
        scrollToElement('.gform_validation_errors');
    }, 500);
});
