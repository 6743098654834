/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import LocomotiveScroll from 'locomotive-scroll';

const locomotiveScroll = new LocomotiveScroll({
    el: document.querySelector("[data-scroll-container]"),
    smooth: true,
    reloadOnContextChange: true, // Cela désactive certains problèmes liés au cache
});

window.locoScroll = locomotiveScroll;