/* eslint-disable prettier/prettier */
const body = document.querySelector('body');
const burger = document.querySelector('.burger');
const curtain = document.querySelector('.curtain');
const mobileNavigation = document.querySelector('.mobile-navigation');
const mobileSubMenu = mobileNavigation.querySelector('.mobile-navigation ul.nav > li.menu-item-has-children > ul.sub-menu');
const mobileSubMenuTrigger = mobileNavigation.querySelector('.mobile-navigation ul.nav > li.menu-item-has-children > a');
const navigation = document.querySelector('header#main-nav');
const productMenu = document.querySelector('.product-menu');
const productMenuTrigger = document.querySelector('.product-menu-trigger');
const quoteBtn = navigation.querySelector('li.quote a');

let lastScrollDistance = 0;
let newScrollPosition = 0;

// BODY PADDING TOP
function bodyPaddingTop() {
    body.style.paddingTop = `${navigation.offsetHeight}px`;
}

// MOBILE MENU PADDING
function mobileNavPaddingTop() {
    mobileNavigation.style.paddingTop = `${navigation.offsetHeight}px`;
}

// MOBILE MENU
if (mobileNavigation) {
    mobileNavigation.addEventListener('wheel', (e) => e.stopPropagation());
    mobileNavigation.addEventListener('touchmove', (e) => e.stopPropagation());

    if (mobileSubMenuTrigger && mobileSubMenu) {
        mobileSubMenuTrigger.addEventListener('click', (e) => {
            e.preventDefault();
            mobileSubMenuTrigger.classList.toggle('active');

            if (mobileSubMenuTrigger.classList.contains('active')) {
                mobileSubMenu.style.maxHeight = `${mobileSubMenu.scrollHeight}px`;
                newScrollPosition = window.scrollY;
            } else {
                mobileSubMenu.style.maxHeight = '0';
            }
        });
    }
}

// NAVIGATION QUOTE BUTTON WORDS SPLITS
function splitQuoteWords() {
    if (quoteBtn) {
        const words = quoteBtn.textContent.split(' ');
        quoteBtn.innerHTML = words
            .map((word) => `<span>${word}</span>`)
            .join(' ');
    }
}

// NAVIGATION SCROLL / HIDE
function navigationOnScroll() {
    const currentScrollDistance = window.scrollY;

    if (!body.classList.contains('menu-shown')) {
        if (currentScrollDistance < 500) {
            navigation.classList.remove('hideNav');
        } else if (currentScrollDistance > lastScrollDistance) {
            navigation.classList.add('hideNav');
        } else {
            navigation.classList.remove('hideNav');
        }

        lastScrollDistance = currentScrollDistance;
    }
}

// PRODUCTS MENU TRIGGER
if (productMenuTrigger && productMenu) {
    productMenuTrigger.addEventListener('click', (e) => {
        e.preventDefault();

        body.classList.toggle('menu-shown');
        body.classList.toggle('product-menu-shown');

        if (body.classList.contains('product-menu-shown')) {
            productMenu.style.maxHeight = `${productMenu.scrollHeight}px`;
        } else {
            productMenu.style.maxHeight = '0';
        }

        if (body.classList.contains('menu-shown')) {
            newScrollPosition = window.scrollY;
        }
    });
}

// KILL MENU
function killMenu() {
    body.classList.remove('menu-shown');
    body.classList.remove('product-menu-shown');

    if (productMenuTrigger && productMenu) {
        productMenu.style.maxHeight = '0';
    }

    if (mobileSubMenuTrigger && mobileSubMenu) {
        mobileSubMenuTrigger.classList.remove('active');
        mobileSubMenu.style.maxHeight = '0';
    }
}

// KILL MENU ON SCROLL
function killMenuOnScroll() {
    if (body.classList.contains('menu-shown')) {
        if (Math.abs(window.scrollY - newScrollPosition) > 250) {
            killMenu();
        }
    }
}

curtain.addEventListener('click', killMenu);
burger.addEventListener('click', () => {
    body.classList.toggle('menu-shown');

    if(!body.classList.contains('menu-shown')) {
        killMenu();
    }
    else {
        newScrollPosition = window.scrollY;
    }
});

// EVENTS
document.addEventListener('DOMContentLoaded', () => {
    bodyPaddingTop();
    mobileNavPaddingTop();
    splitQuoteWords();
});

window.addEventListener('scroll', () => {
    navigationOnScroll();
    killMenuOnScroll();
});

window.addEventListener('resize', () => {
    bodyPaddingTop();
    mobileNavPaddingTop();
});
