/* eslint-disable import/first */
import Alpine from 'alpinejs';
import testA11y from './utils/test-a11y.js';

// eslint-disable-next-line no-undef
window.Alpine = Alpine;

Alpine.start();

// If we have the test_a11y query param, run the a11y tests
if (window.location.search.includes('test_a11y')) {
    testA11y(document);
}

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error); // eslint-disable-line no-console

import './_accordions.js';
import './_bodyLoad.js';
import './_insight.js';
import './_navigation.js';
import './_parallax.js';
import './_fancybox.js';
import './_splide.js';
import './_single-project.js';
import './_forms.js';
